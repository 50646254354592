<template>
    <div class="container">
        <b-row class="justify-content-center" v-if="totalPayment">
            <b-col cols="3">
                <b-card v-if="totalPayment.user">
                    <h3 class="font-weight-bolder text-center">To</h3>
                    <b-avatar size="88px" :src="totalPayment.user.thumbnail" />
                    <span class="ml-1 text-secondary">
                        {{ totalPayment.user.name }}
                    </span>
                </b-card>
            </b-col>
            <b-col cols="5">
                <b-card class="text-center">
                    <h3 class="font-weight-bolder mb-2 text-center">Wallet ballance</h3>
                    <span class="wallet-balance"> {{ totalPayment.wallet.wallet_balance.NojoumCoin }} <b-avatar class="mb-50" src="./images/COIN.png" /> </span>
                </b-card>
                <b-card class="text-center">
                    <b-row class="justify-content-center">
                        <h2 class="font-weight-bolder mb-1">Info</h2>
                    </b-row>
                    <b-row class="justify-content-center">
                        <b-col class="text-center" cols="4">
                            <h5>Transaction status</h5>
                            <b-badge variant="light-success"> {{ totalPayment.transaction.transaction_status }} </b-badge>
                        </b-col>
                        <b-col class="text-center" cols="4">
                            <h5>Transaction type</h5>
                            <b-badge variant="light-primary"> {{ totalPayment.transaction.transaction_type }} </b-badge>
                        </b-col>
                        <b-col class="text-center" cols="4">
                            <h5>Transaction time</h5>
                            <b-row class="justify-content-center">
                                <b-badge variant="light-secondary"> {{ totalPayment.transaction_time.date }} </b-badge>
                            </b-row>
                            <b-row class="mt-25 justify-content-center">
                                <b-badge variant="light-secondary">
                                    {{ totalPayment.transaction_time.time }}
                                </b-badge>
                            </b-row>
                            <b-row class="mt-25 justify-content-center">
                                <b-badge variant="light-secondary">
                                    {{ totalPayment.transaction_time.timezone }}
                                </b-badge>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="3">
                <b-card v-if="totalPayment.causer">
                    <h3 class="font-weight-bolder text-center">From</h3>
                    <b-avatar size="88px" :src="totalPayment.causer.thumbnail" />
                    <span class="ml-1 text-secondary">
                        {{ totalPayment.causer.name }}
                    </span>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="justify-content-center">
            <b-col cols="3"> </b-col>
            <b-col cols="5"> </b-col>
        </b-row>
    </div>
</template>

<script>
import { BAvatar, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormRadioGroup, BFormCheckbox, BBadge } from "bootstrap-vue";
import { ref, onUnmounted, onMounted, watch } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import useWalletList from "./useWalletList";
export default {
    components: {
        BBadge,
        BAvatar,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormRadioGroup,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    setup() {
        const totalPaymentId = ref(router.currentRoute.params.id);
        const totalPayment = ref();
        const { fetchTotalPaymentsDetails } = useWalletList();
        fetchTotalPaymentsDetails(totalPaymentId.value).then((res) => {
            totalPayment.value = res.data.data;
            console.log(totalPayment.value);
        });
        return {
            totalPaymentId,
            totalPayment,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.wallet-balance {
    font-size: 50px;
    margin-top: 60px;
}
</style>
